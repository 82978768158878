import React from 'react'

import styles from './TrustPilotStars.module.scss'

const TrustPilotStar: React.FunctionComponent = () => (
  <figure>
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 11.733l4.947 3.6-1.894-5.813L16 6H9.933L8 0 6.067 6H0l4.947 3.52-1.894 5.813L8 11.733z" />
    </svg>
  </figure>
)

interface TrustPilotStarsProps {
  stars: number
}

export const TrustPilotStars: React.FunctionComponent<TrustPilotStarsProps> = ({
  stars,
}) => {
  return (
    <div className={styles.stars} aria-label="Stars" role="img">
      {Array.from(Array(stars)).map((_, idx) => (
        <TrustPilotStar key={idx} />
      ))}
    </div>
  )
}

export const TrustPilot4Stars: React.FunctionComponent = () => (
  <svg width="80px" height="15px" viewBox="0 0 512 96" version="1.1">
    <g
      id="Trustpilot_ratings_4star-RGB"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g>
        <g>
          <rect
            id="Rectangle-path"
            fill="#73CF11"
            fillRule="nonzero"
            x="0"
            y="0"
            width="96"
            height="96"
          />
          <rect
            id="Rectangle-path"
            fill="#73CF11"
            fillRule="nonzero"
            x="104"
            y="0"
            width="96"
            height="96"
          />
          <rect
            id="Rectangle-path"
            fill="#73CF11"
            fillRule="nonzero"
            x="208"
            y="0"
            width="96"
            height="96"
          />
          <rect
            id="Rectangle-path"
            fill="#73CF11"
            fillRule="nonzero"
            x="312"
            y="0"
            width="96"
            height="96"
          />
          <rect
            id="Rectangle-path"
            fill="#DCDCE6"
            fillRule="nonzero"
            x="416"
            y="0"
            width="96"
            height="96"
          />
          <path
            d="M48,64.7 L62.6,61 L68.7,79.8 L48,64.7 Z M81.6,40.4 L55.9,40.4 L48,16.2 L40.1,40.4 L14.4,40.4 L35.2,55.4 L27.3,79.6 L48.1,64.6 L60.9,55.4 L81.6,40.4 L81.6,40.4 L81.6,40.4 L81.6,40.4 Z"
            id="Shape"
            fill="#FFFFFF"
            fillRule="nonzero"
          />
          <path
            d="M152,64.7 L166.6,61 L172.7,79.8 L152,64.7 Z M185.6,40.4 L159.9,40.4 L152,16.2 L144.1,40.4 L118.4,40.4 L139.2,55.4 L131.3,79.6 L152.1,64.6 L164.9,55.4 L185.6,40.4 L185.6,40.4 L185.6,40.4 L185.6,40.4 Z"
            id="Shape"
            fill="#FFFFFF"
            fillRule="nonzero"
          />
          <path
            d="M256,64.7 L270.6,61 L276.7,79.8 L256,64.7 Z M289.6,40.4 L263.9,40.4 L256,16.2 L248.1,40.4 L222.4,40.4 L243.2,55.4 L235.3,79.6 L256.1,64.6 L268.9,55.4 L289.6,40.4 L289.6,40.4 L289.6,40.4 L289.6,40.4 Z"
            id="Shape"
            fill="#FFFFFF"
            fillRule="nonzero"
          />
          <path
            d="M360,64.7 L374.6,61 L380.7,79.8 L360,64.7 Z M393.6,40.4 L367.9,40.4 L360,16.2 L352.1,40.4 L326.4,40.4 L347.2,55.4 L339.3,79.6 L360.1,64.6 L372.9,55.4 L393.6,40.4 L393.6,40.4 L393.6,40.4 L393.6,40.4 Z"
            id="Shape"
            fill="#FFFFFF"
            fillRule="nonzero"
          />
          <path
            d="M464,64.7 L478.6,61 L484.7,79.8 L464,64.7 Z M497.6,40.4 L471.9,40.4 L464,16.2 L456.1,40.4 L430.4,40.4 L451.2,55.4 L443.3,79.6 L464.1,64.6 L476.9,55.4 L497.6,40.4 L497.6,40.4 L497.6,40.4 L497.6,40.4 Z"
            id="Shape"
            fill="#FFFFFF"
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
  </svg>
)

export const TrustPilot45Stars: React.FunctionComponent = () => (
  <svg width="80px" height="15px" viewBox="0 0 512 96" version="1.1">
    <g
      id="Trustpilot_ratings_4halfstar-RGB"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g>
        <g>
          <rect
            id="Rectangle-path"
            fill="#00B67A"
            fillRule="nonzero"
            x="0"
            y="0"
            width="96"
            height="96"
          />
          <rect
            id="Rectangle-path"
            fill="#00B67A"
            fillRule="nonzero"
            x="104"
            y="0"
            width="96"
            height="96"
          />
          <rect
            id="Rectangle-path"
            fill="#00B67A"
            fillRule="nonzero"
            x="208"
            y="0"
            width="96"
            height="96"
          />
          <rect
            id="Rectangle-path"
            fill="#00B67A"
            fillRule="nonzero"
            x="312"
            y="0"
            width="96"
            height="96"
          />
          <g
            id="Half"
            transform="translate(416.000000, 0.000000)"
            fillRule="nonzero"
          >
            <rect
              id="Rectangle-path"
              fill="#DCDCE6"
              x="48"
              y="0"
              width="48"
              height="96"
            />
            <rect
              id="Rectangle-path"
              fill="#00B67A"
              x="0"
              y="0"
              width="48"
              height="96"
            />
          </g>
          <path
            d="M48,64.7 L62.6,61 L68.7,79.8 L48,64.7 Z M81.6,40.4 L55.9,40.4 L48,16.2 L40.1,40.4 L14.4,40.4 L35.2,55.4 L27.3,79.6 L48.1,64.6 L60.9,55.4 L81.6,40.4 L81.6,40.4 L81.6,40.4 L81.6,40.4 Z"
            id="Shape"
            fill="#FFFFFF"
            fillRule="nonzero"
          />
          <path
            d="M152,64.7 L166.6,61 L172.7,79.8 L152,64.7 Z M185.6,40.4 L159.9,40.4 L152,16.2 L144.1,40.4 L118.4,40.4 L139.2,55.4 L131.3,79.6 L152.1,64.6 L164.9,55.4 L185.6,40.4 L185.6,40.4 L185.6,40.4 L185.6,40.4 Z"
            id="Shape"
            fill="#FFFFFF"
            fillRule="nonzero"
          />
          <path
            d="M256,64.7 L270.6,61 L276.7,79.8 L256,64.7 Z M289.6,40.4 L263.9,40.4 L256,16.2 L248.1,40.4 L222.4,40.4 L243.2,55.4 L235.3,79.6 L256.1,64.6 L268.9,55.4 L289.6,40.4 L289.6,40.4 L289.6,40.4 L289.6,40.4 Z"
            id="Shape"
            fill="#FFFFFF"
            fillRule="nonzero"
          />
          <path
            d="M360,64.7 L374.6,61 L380.7,79.8 L360,64.7 Z M393.6,40.4 L367.9,40.4 L360,16.2 L352.1,40.4 L326.4,40.4 L347.2,55.4 L339.3,79.6 L360.1,64.6 L372.9,55.4 L393.6,40.4 L393.6,40.4 L393.6,40.4 L393.6,40.4 Z"
            id="Shape"
            fill="#FFFFFF"
            fillRule="nonzero"
          />
          <path
            d="M464,64.7 L478.6,61 L484.7,79.8 L464,64.7 Z M497.6,40.4 L471.9,40.4 L464,16.2 L456.1,40.4 L430.4,40.4 L451.2,55.4 L443.3,79.6 L464.1,64.6 L476.9,55.4 L497.6,40.4 L497.6,40.4 L497.6,40.4 L497.6,40.4 Z"
            id="Shape"
            fill="#FFFFFF"
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
  </svg>
)

export const TrustPilot46Stars: React.FunctionComponent = () => (
  <svg
    width="79"
    height="15"
    viewBox="0 0 79 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="14.5569" height="14.5569" rx="0.526483" fill="#51B27F" />
    <path
      d="M9.56099 9.15988C9.6149 9.14591 9.67036 9.17632 9.68756 9.22928L10.553 11.8937C10.5846 11.9907 10.4735 12.0714 10.391 12.0114L7.46139 9.88208C7.38942 9.82977 7.41075 9.71731 7.49688 9.69498L9.56099 9.15988ZM12.738 5.78722C12.738 5.78705 12.7379 5.78692 12.7377 5.78692H8.64534C8.59971 5.78692 8.55927 5.75753 8.54519 5.71412L7.38142 2.12783C7.34994 2.03082 7.21272 2.03076 7.18114 2.12774L6.01321 5.71503C5.99908 5.75843 5.95859 5.78779 5.91295 5.78773L2.14488 5.78279C2.04283 5.78266 2.00028 5.91323 2.08282 5.97325L5.1347 8.19247C5.17161 8.21931 5.18703 8.26687 5.17289 8.31026L4.00519 11.8931C3.97357 11.9901 4.08459 12.0709 4.16716 12.011L7.28098 9.7505L9.3658 8.23749L12.7379 5.78746C12.738 5.78741 12.738 5.78732 12.738 5.78722V5.78722Z"
      fill="white"
    />
    <rect
      x="16.0586"
      width="14.5569"
      height="14.5569"
      rx="0.526483"
      fill="#51B27F"
    />
    <path
      d="M25.6157 9.15952C25.6696 9.14554 25.725 9.17595 25.7422 9.22891L26.6077 11.8933C26.6392 11.9904 26.5282 12.071 26.4457 12.011L23.3357 9.75058L25.6157 9.15952ZM28.5306 5.97703C28.6131 5.91707 28.5707 5.78655 28.4687 5.78655H24.7C24.6544 5.78655 24.614 5.75716 24.5999 5.71376L23.4361 2.12747C23.4046 2.03046 23.2674 2.03039 23.2358 2.12737L22.0679 5.71466C22.0538 5.75807 22.0133 5.78742 21.9676 5.78736L18.1996 5.78243C18.0975 5.78229 18.055 5.91287 18.1375 5.97289L21.1894 8.1921C21.2263 8.21894 21.2417 8.2665 21.2276 8.30989L20.0599 11.8928C20.0282 11.9898 20.1393 12.0706 20.2218 12.0107L23.3357 9.75058L25.4205 8.23712L28.5306 5.97703Z"
      fill="white"
    />
    <rect
      x="32.1172"
      width="14.5569"
      height="14.5569"
      rx="0.526483"
      fill="#51B27F"
    />
    <path
      d="M41.6782 9.15988C41.7321 9.14591 41.7875 9.17632 41.8047 9.22928L42.6702 11.8937C42.7017 11.9907 42.5907 12.0714 42.5082 12.0114L39.5786 9.88208C39.5066 9.82977 39.5279 9.71731 39.6141 9.69498L41.6782 9.15988ZM44.8552 5.78722C44.8552 5.78705 44.8551 5.78692 44.8549 5.78692H40.7625C40.7169 5.78692 40.6765 5.75753 40.6624 5.71412L39.4986 2.12783C39.4671 2.03082 39.3299 2.03076 39.2983 2.12774L38.1304 5.71503C38.1163 5.75843 38.0758 5.78779 38.0301 5.78773L34.2621 5.78279C34.16 5.78266 34.1175 5.91323 34.2 5.97325L37.2519 8.19247C37.2888 8.21931 37.3042 8.26687 37.2901 8.31026L36.1224 11.8931C36.0908 11.9901 36.2018 12.0709 36.2843 12.011L39.3982 9.7505L41.483 8.23749L44.8551 5.78746C44.8551 5.78741 44.8552 5.78732 44.8552 5.78722V5.78722Z"
      fill="white"
    />
    <rect
      x="48.1758"
      width="14.5569"
      height="14.5569"
      rx="0.526483"
      fill="#51B27F"
    />
    <path
      d="M57.7329 9.15952C57.7868 9.14554 57.8422 9.17595 57.8594 9.22891L58.7249 11.8933C58.7564 11.9904 58.6454 12.071 58.5629 12.011L55.6333 9.88172C55.5613 9.82941 55.5826 9.71694 55.6688 9.69461L57.7329 9.15952ZM60.9099 5.78685C60.9099 5.78669 60.9097 5.78655 60.9096 5.78655H56.8172C56.7716 5.78655 56.7311 5.75716 56.7171 5.71376L55.5533 2.12747C55.5218 2.03046 55.3846 2.03039 55.353 2.12737L54.1851 5.71466C54.171 5.75807 54.1305 5.78742 54.0848 5.78736L50.3168 5.78243C50.2147 5.78229 50.1722 5.91287 50.2547 5.97289L53.3066 8.1921C53.3435 8.21894 53.3589 8.2665 53.3448 8.30989L52.1771 11.8928C52.1454 11.9898 52.2565 12.0705 52.339 12.0106L55.4529 9.75013L57.5377 8.23712L60.9098 5.7871C60.9098 5.78704 60.9099 5.78695 60.9099 5.78685V5.78685Z"
      fill="white"
    />
    <path
      d="M64.2344 0.526484C64.2344 0.235715 64.4701 0 64.7609 0H69.0836V14.5569H64.7609C64.4701 14.5569 64.2344 14.3212 64.2344 14.0304V0.526484Z"
      fill="#51B27F"
    />
    <rect x="68.7852" width="4.84927" height="14.5569" fill="#51B27F" />
    <path
      d="M73.332 0H77.6548C77.9456 0 78.1813 0.235715 78.1813 0.526483V14.0304C78.1813 14.3212 77.9456 14.5569 77.6548 14.5569H73.332V0Z"
      fill="#C2C2C2"
    />
    <path
      d="M73.8366 9.31531L74.8329 12.3823L71.4607 9.93125L73.8366 9.31531ZM76.9177 5.96722H72.7485L71.4611 2L70.1692 5.96813L66 5.96267L69.3763 8.41778L68.0844 12.3818L71.4607 9.93079L73.5455 8.41778L76.9177 5.96767V5.96722Z"
      fill="white"
    />
  </svg>
)

export const TrustPilot48Stars: React.FunctionComponent = () => (
  <svg
    width="79"
    height="15"
    viewBox="0 0 79 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="14.5569" height="14.5569" rx="0.526483" fill="#51B27F" />
    <path
      d="M9.56099 9.15988C9.6149 9.14591 9.67036 9.17632 9.68756 9.22928L10.553 11.8937C10.5846 11.9907 10.4735 12.0714 10.391 12.0114L7.46139 9.88208C7.38942 9.82977 7.41075 9.71731 7.49688 9.69498L9.56099 9.15988ZM12.738 5.78722C12.738 5.78705 12.7379 5.78692 12.7377 5.78692H8.64534C8.59971 5.78692 8.55927 5.75753 8.54519 5.71412L7.38142 2.12783C7.34994 2.03082 7.21272 2.03076 7.18114 2.12774L6.01321 5.71503C5.99908 5.75843 5.95859 5.78779 5.91295 5.78773L2.14488 5.78279C2.04283 5.78266 2.00028 5.91323 2.08282 5.97325L5.1347 8.19247C5.17161 8.21931 5.18703 8.26687 5.17289 8.31026L4.00519 11.8931C3.97357 11.9901 4.08459 12.0709 4.16716 12.011L7.28098 9.7505L9.3658 8.23749L12.7379 5.78746C12.738 5.78741 12.738 5.78732 12.738 5.78722Z"
      fill="white"
    />
    <rect
      x="16.0586"
      width="14.5569"
      height="14.5569"
      rx="0.526483"
      fill="#51B27F"
    />
    <path
      d="M25.6157 9.15952C25.6696 9.14554 25.725 9.17595 25.7422 9.22891L26.6077 11.8933C26.6392 11.9904 26.5282 12.071 26.4457 12.011L23.3357 9.75058L25.6157 9.15952ZM28.5306 5.97703C28.6131 5.91707 28.5707 5.78655 28.4687 5.78655H24.7C24.6544 5.78655 24.614 5.75716 24.5999 5.71376L23.4361 2.12747C23.4046 2.03046 23.2674 2.03039 23.2358 2.12737L22.0679 5.71466C22.0538 5.75807 22.0133 5.78742 21.9676 5.78736L18.1996 5.78243C18.0975 5.78229 18.055 5.91287 18.1375 5.97289L21.1894 8.1921C21.2263 8.21894 21.2417 8.2665 21.2276 8.30989L20.0599 11.8928C20.0282 11.9898 20.1393 12.0706 20.2218 12.0107L23.3357 9.75058L25.4205 8.23712L28.5306 5.97703Z"
      fill="white"
    />
    <rect
      x="32.1172"
      width="14.5569"
      height="14.5569"
      rx="0.526483"
      fill="#51B27F"
    />
    <path
      d="M41.6782 9.15988C41.7321 9.14591 41.7875 9.17632 41.8047 9.22928L42.6702 11.8937C42.7017 11.9907 42.5907 12.0714 42.5082 12.0114L39.5786 9.88208C39.5066 9.82977 39.5279 9.71731 39.6141 9.69498L41.6782 9.15988ZM44.8552 5.78722C44.8552 5.78705 44.8551 5.78692 44.8549 5.78692H40.7625C40.7169 5.78692 40.6765 5.75753 40.6624 5.71412L39.4986 2.12783C39.4671 2.03082 39.3299 2.03076 39.2983 2.12774L38.1304 5.71503C38.1163 5.75843 38.0758 5.78779 38.0301 5.78773L34.2621 5.78279C34.16 5.78266 34.1175 5.91323 34.2 5.97325L37.2519 8.19247C37.2888 8.21931 37.3042 8.26687 37.2901 8.31026L36.1224 11.8931C36.0908 11.9901 36.2018 12.0709 36.2843 12.011L39.3982 9.7505L41.483 8.23749L44.8551 5.78746C44.8551 5.78741 44.8552 5.78732 44.8552 5.78722Z"
      fill="white"
    />
    <rect
      x="48.1758"
      width="14.5569"
      height="14.5569"
      rx="0.526483"
      fill="#51B27F"
    />
    <path
      d="M57.7329 9.15952C57.7868 9.14554 57.8422 9.17595 57.8594 9.22891L58.7249 11.8933C58.7564 11.9904 58.6454 12.071 58.5629 12.011L55.6333 9.88172C55.5613 9.82941 55.5826 9.71694 55.6688 9.69461L57.7329 9.15952ZM60.9099 5.78685C60.9099 5.78669 60.9097 5.78655 60.9096 5.78655H56.8172C56.7716 5.78655 56.7311 5.75716 56.7171 5.71376L55.5533 2.12747C55.5218 2.03046 55.3846 2.03039 55.353 2.12737L54.1851 5.71466C54.171 5.75807 54.1305 5.78742 54.0848 5.78736L50.3168 5.78243C50.2147 5.78229 50.1722 5.91287 50.2547 5.97289L53.3066 8.1921C53.3435 8.21894 53.3589 8.2665 53.3448 8.30989L52.1771 11.8928C52.1454 11.9898 52.2565 12.0705 52.339 12.0106L55.4529 9.75013L57.5377 8.23712L60.9098 5.7871C60.9098 5.78704 60.9099 5.78695 60.9099 5.78685Z"
      fill="white"
    />
    <path
      d="M64.2344 0.526484C64.2344 0.235715 64.4701 0 64.7609 0H70.2344V14.5569H64.7609C64.4701 14.5569 64.2344 14.3212 64.2344 14.0304V0.526484Z"
      fill="#51B27F"
    />
    <path
      d="M73.332 0H77.6548C77.9456 0 78.1813 0.235715 78.1813 0.526483V14.0304C78.1813 14.3212 77.9456 14.5569 77.6548 14.5569H73.332V0Z"
      fill="#C2C2C2"
    />
    <rect x="70" width="4.84927" height="14.5569" fill="#51B27F" />
    <path
      d="M73.8366 9.31531L74.8329 12.3823L71.4607 9.93125L73.8366 9.31531ZM76.9177 5.96722H72.7485L71.4611 2L70.1692 5.96813L66 5.96267L69.3763 8.41778L68.0844 12.3818L71.4607 9.93079L73.5455 8.41778L76.9177 5.96767V5.96722Z"
      fill="white"
    />
  </svg>
)

export const TrustPilot5Stars: React.FunctionComponent = () => (
  <svg width="80px" height="15px" viewBox="0 0 512 96" version="1.1">
    <g
      id="Trustpilot_ratings_5star-RGB"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g>
        <g>
          <rect
            id="Rectangle-path"
            fill="#00B67A"
            fillRule="nonzero"
            x="0"
            y="0"
            width="96"
            height="96"
          />
          <rect
            id="Rectangle-path"
            fill="#00B67A"
            fillRule="nonzero"
            x="104"
            y="0"
            width="96"
            height="96"
          />
          <rect
            id="Rectangle-path"
            fill="#00B67A"
            fillRule="nonzero"
            x="208"
            y="0"
            width="96"
            height="96"
          />
          <rect
            id="Rectangle-path"
            fill="#00B67A"
            fillRule="nonzero"
            x="312"
            y="0"
            width="96"
            height="96"
          />
          <rect
            id="Rectangle-path"
            fill="#00B67A"
            fillRule="nonzero"
            x="416"
            y="0"
            width="96"
            height="96"
          />
          <path
            d="M48,64.7 L62.6,61 L68.7,79.8 L48,64.7 Z M81.6,40.4 L55.9,40.4 L48,16.2 L40.1,40.4 L14.4,40.4 L35.2,55.4 L27.3,79.6 L48.1,64.6 L60.9,55.4 L81.6,40.4 L81.6,40.4 L81.6,40.4 L81.6,40.4 Z"
            id="Shape"
            fill="#FFFFFF"
            fillRule="nonzero"
          />
          <path
            d="M152,64.7 L166.6,61 L172.7,79.8 L152,64.7 Z M185.6,40.4 L159.9,40.4 L152,16.2 L144.1,40.4 L118.4,40.4 L139.2,55.4 L131.3,79.6 L152.1,64.6 L164.9,55.4 L185.6,40.4 L185.6,40.4 L185.6,40.4 L185.6,40.4 Z"
            id="Shape"
            fill="#FFFFFF"
            fillRule="nonzero"
          />
          <path
            d="M256,64.7 L270.6,61 L276.7,79.8 L256,64.7 Z M289.6,40.4 L263.9,40.4 L256,16.2 L248.1,40.4 L222.4,40.4 L243.2,55.4 L235.3,79.6 L256.1,64.6 L268.9,55.4 L289.6,40.4 L289.6,40.4 L289.6,40.4 L289.6,40.4 Z"
            id="Shape"
            fill="#FFFFFF"
            fillRule="nonzero"
          />
          <path
            d="M360,64.7 L374.6,61 L380.7,79.8 L360,64.7 Z M393.6,40.4 L367.9,40.4 L360,16.2 L352.1,40.4 L326.4,40.4 L347.2,55.4 L339.3,79.6 L360.1,64.6 L372.9,55.4 L393.6,40.4 L393.6,40.4 L393.6,40.4 L393.6,40.4 Z"
            id="Shape"
            fill="#FFFFFF"
            fillRule="nonzero"
          />
          <path
            d="M464,64.7 L478.6,61 L484.7,79.8 L464,64.7 Z M497.6,40.4 L471.9,40.4 L464,16.2 L456.1,40.4 L430.4,40.4 L451.2,55.4 L443.3,79.6 L464.1,64.6 L476.9,55.4 L497.6,40.4 L497.6,40.4 L497.6,40.4 L497.6,40.4 Z"
            id="Shape"
            fill="#FFFFFF"
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
  </svg>
)
